<template>
    <div class="match_full_detail">
        <div class="text-center" v-if="!is_data_ready">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="pg-two-columns" v-else>
            <left-sidebar></left-sidebar>
            <div v-if="matches.length == 0">
              {{ ($route.name == 'myMatches')? 'No my match available' : ' No match available' }}
            </div>
            <div class="pg-content" v-else>
                <match-card class="cd_cr_list" v-for="(grouped_matches, _key) in matches" :key="_key" :grouped_matches="grouped_matches" :_key="_key" v-on:toggleFavourite="removeFavorite($event, _key)"></match-card>
            </div>
        </div>
        <!-- <match-card v-for="(match, tour_key) in matches" :key="tour_key" :match="match" :tour_key="tour_key" v-on:toggleFavourite="removeFavorite($event)" v-else></match-card> -->
    </div>
</template>

<script>
    import MatchCard from './match-card';
    import moment from "moment";
    import LeftSidebar from './Common/left-sidebar.vue';

    export default {
        name: "match-listing",
        data() {
            return {
                matches: [],
                tournaments: [],
            }
        },
        components: {
            MatchCard,
            LeftSidebar
        },
        jsonld() {

            if (isServer)
                return {
                    "@context": "http://schema.org",
                    "@graph": []
                }

            if (!this.matches)
                return
            let matches = Object.values(this.matches).flat(1) || [];
            let events = [];
            matches.forEach((match) => {

                let stadiumAddress = '';
                if(match.stadium) {
                    stadiumAddress = match.stadium.name + ", " + match.stadium.city + ", " + match.stadium.country;
                } else {
                    stadiumAddress = '';
                }

                events.push(
                    {
                        "@type": "SportsEvent",
                        "name": match.team1.name + " VS " + match.team2.name,
                        "description": match.tournament.name + " - " + match.team1.name + " VS " + match.team2.name + " - " + match.matchno,
                        "url": this.$siteURL + "live/cricket/match-detail/" + match.slug + "/" + match.id + "/info",
                        "eventStatus": "http://schema.org/EventScheduled",
                        "eventAttendanceMode": "http://schema.org/OfflineEventAttendanceMode",
                        "startDate": moment(match.gmt_datetime).format('YYYY-MM-DD HH:MM'),
                        "endDate": moment(match.gmt_datetime).format('YYYY-MM-DD'),
                        "image": this.$siteURL + 'public/images/logo.png',
                        "organizer": {
                            "@type": "Organization",
                            "name": match.tournament.name,
                            "url": this.$siteURL + "tournament/" + match.tournament.slug + "/matches",
                        },
                        "offers": {
                            "@type": "Offer",
                            "name": match.team1.name + " VS " + match.team2.name,
                            "url": this.$siteURL + "live/cricket/match-detail/" + match.slug + "/" + match.id + "/info",
                            "priceCurrency": "EUR",
                            "validFrom": moment(match.gmt_datetime).format('YYYY-MM-DD HH:MM'),
                            "availability": "https://schema.org/InStock",
                            "price": "0"
                        },
                        "performer": {
                            "@type": "Thing",
                            "name": match.team1.name + " VS " + match.team2.name,
                        },
                        "competitor": [
                            {
                                "@type": "SportsTeam",
                                "name": match.team1.name,
                                "image": match.team1.flag
                            },
                            {
                                "@type": "SportsTeam",
                                "name": match.team2.name,
                                "image": match.team2.flag
                            }
                        ],
                        "location": {
                            "@type": "Place",
                            "name": stadiumAddress,
                            "address": {
                                "@type": "PostalAddress",
                                "name": stadiumAddress,
                            }
                        }
                    }
                )
            });
            return {
                "@context": "http://schema.org",
                "@graph": events
            };

        },

        mounted() {
            this.main();
            var _this = this;
            this.$root.$on('CricMatchUpdated', data => {
                if (_this.matches && _this.matches.find(match => match.id == data.match_id)) {
                    _this.main(true)
                }
            });
        },
        beforeDestroy() {
            this.$root.$off('CricMatchUpdated')
        },
        watch: {
            '$route.params.date'() {
                this.main();
            },
            '$route.params.type'() {
                this.main();
            }
        },
        serverPrefetch() {
            this.main();
        },
        metaInfo() {
            if (this.$store.state.siteSetting) {

                let ss = this.$store.state.siteSetting;
                var replacements = {
                    '%DATE%': (this.$route.params.date == moment(new Date()).format("YYYY-MM-DD")) ? 'Today' : this.$route.params.date,
                    '%TAB%': this.$getSafe(() => this.$route.params.type.charAt(0).toUpperCase() + this.$route.params.type.substring(1)),
                };

                let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_listing_meta_title,replacements));
                let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_listing_meta_description,replacements));
                let keywords = this.$getSafe(() => ss.cricket_listing_meta_keywords);

                // my matches
                if (this.$route.name == 'myMatches') {
                    title = this.$getSafe(() => ss.cricket_my_matches_meta_title);
                    description = this.$getSafe(() => cricket_my_matches_meta_description);
                    keywords = this.$getSafe(() => cricket_my_matches_meta_keywords);
                }

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
                };
            }
        },
        methods: {
          main(is_socket = false) {
              /** Get Grouped Matches listing */
              let params = {date: this.$route.params.date, type: this.$route.params.type}
              if (this.$route.name == 'myMatches') {
                  params.favourites = this.$store.getters.favouriteMatches.length > 0 ? this.$store.getters.favouriteMatches : ['']
                  delete params.date;
              }
              if (!is_socket) this.is_data_ready = false;
              return this.$axios
                  .get(this.$MATCHES_API, {params})
                  .then(response => {
                      if (response.data.result) {
                          this.matches = response.data.result.matches;
                      }
                      this.is_data_ready = true;
                  })
                  .catch(error => console.log(error));
            },
            removeFavorite(slug,tour_key) {
                if (this.$route.name == 'myMatches') {
                    var match_list = this.matches[tour_key];
                    this.matches[tour_key].forEach(function (val, key) {
                        if (val.slug == slug) {
                            match_list.splice(key, 1);
                        }
                    });
                }
            }
        },
    }
</script>
